<template>
    <el-dialog v-if="m" :title="title" :visible.sync="isShow" width="550px" top="10vh" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog">
        <div class="vue-box">
            <!-- 参数栏 -->
            <div class="c-panel">
                <el-form size="mini" v-if="m" ref="ruleForm" :rules="rules" :model="m" class="demo-ruleForm" label-width="120px">
                    <el-form-item label="产品名" prop="title">
                        <el-input v-model="m.title"></el-input>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input type="textarea" rows="2" placeholder="备注" v-model="m.info"></el-input>
                    </el-form-item>
                    <el-form-item label="封面">
                        <el-upload class="upload-demo" :action="sa.cfg.api_url + '/file/upload'" :multiple="false" :data="{ fileType: 2, params: '' }" :limit="10" :on-success="success_imag" :before-remove="remove_imag" :file-list="m.imagFile">
                            <el-button size="mini" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip">上传封面</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item>
                        <span class="c-label">&emsp;</span>
                        <el-button type="primary" icon="el-icon-plus" size="small" @click="ok('ruleForm')">确定
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
//import inputEnum from "../../sa-resources/com-view/input-enum.vue";
export default {
    //components: { inputEnum },
    props: ["params"],
    data() {
        return {
            m: {},
            title: "",
            isShow: false,
            rules: { title: [], info: [], imag: [] },
            fileList: []
        };
    },
    methods: {
        open: function(data) {
            this.isShow = true;
            if (data.id) {
                this.title = "修改 产品主数据";
                data.imagFile = JSON.parse(data.imag);
                this.m = data;
            } else {
                this.m = { title: "", info: "", imag: "", imagFile: [], state:'', category:'', productId: data.productId, productTitle: data.productTitle };
                this.title = "添加产品主数据";
            }
        },
        success_imag(response, file, fileList) {
            if (response.code != 200) {
                this.sa.error(response.message);
                return;
            }
            if (!this.m.imagFile) {
                this.m.imagFile = [];
            }
            this.m.imagFile.push(response.data);
            console.log(fileList);
        },
        remove_imag(file, fileList) {
            this.m.imagFile = fileList;
        },

        //提交产品主数据信息
        ok: function(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.m.imag = JSON.stringify(
                        this.m.imagFile.map(item => {
                            let a = {};
                            a.name = item.name;
                            a.url = item.url;
                            return a;
                        })
                    );
                    this.sa.post("/product/save", this.m).then(res => {
                        console.log(res);
                        this.$parent.f5();
                        this.isShow = false;
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    },
    created() {}
};
</script>